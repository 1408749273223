import WrapperProvider from "@providers/WrapperProvider";
import HomeContainer from "@containers/HomeContainer";
import LocomotiveProvider from "@providers/LocomotiveProvider";
import Layout from "@components/Layout";
import { getServer } from "@translations/next";
import _ from "lodash";
import StrapiService from "@services/StrapiService";
export default function Home(props: any) {
  return (
    <WrapperProvider pageContext={props}>
      <Layout>
        <LocomotiveProvider
          options={{
            smooth: true,
            getSpeed: true,
            getDirection: true,
            reloadOnContextChange: true,
            mobile: {
              smooth: true,
            },
            tablet: {
              smooth: true,
            },
          }}
        >
          <HomeContainer />
        </LocomotiveProvider>
      </Layout>
    </WrapperProvider>
  );
}
export const getServerSideProps = getServer(async ({ params }) => {
  const locale: any = _.get(params, "locale", "vi");
  StrapiService.set("id", "group-home-page");
  const res = await StrapiService.find({ populate: "deep", locale });
  return {
    pageData: _.get(res, "data", {}),
  };
});
